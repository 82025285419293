<template>
    <AppOnePageDropdown
        ref="dropdown"
        v-model="value"
        :idValue="idValue"
        optionLabel="name"
        :filterFields="['codigoExterno', 'name']"
        placeholder="Selecione o setor..."
        :service="$service"
        :itemSize="itemSize"
        :resultWithItems="false"
        :renderValue="customRenderValue"
        :renderOption="customRenderOption"
    >
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '../../../../services/BaseService';
export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        },
        customerId: {
            type: Number
        },
        branchId: {
            type: Number
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        const customerId = this.customerId ?? 0;
        this.$service = new BaseService(`/customer/departments/combo-by-customer/${customerId}`);
    },
    watch: {
        customerId() {
            if (!this.customerId || !this.branchId) return;
            this.$service = new BaseService(`/customer/departments/combo-by-customer/${this.customerId}/${this.branchId}`);
            setTimeout(() => {
                this.$refs.dropdown.load({ itemSize: this.itemSize });
            }, 100);
        },
        branchId() {
            if (!this.customerId || !this.branchId) return;
            this.$service = new BaseService(`/customer/departments/combo-by-customer/${this.customerId}/${this.branchId}`);
            setTimeout(() => {
                this.$refs.dropdown.load({ itemSize: this.itemSize });
            }, 100);
        },
    },
    methods: {
        customRenderOption(slotProps) {
            const nome =
                (slotProps.option?.codigoExterno ? slotProps.option?.codigoExterno  + ' - ' : '') +
                slotProps.option?.name;
            
            return nome;
        },
        customRenderValue(slotProps) {
            const nome =
                (slotProps.value?.codigoExterno ? slotProps.value?.codigoExterno  + ' - ' : '') +
                slotProps.value?.name;
            
            return nome;
        }
    }
};
</script>
